import React from 'react'
import './Cuenta.css';

const Cuenta = ({payments}) => {

    // console.log("gotpayments", payments);

    return (
        <div className= "seccion-cuenta hover-seccion">
            <div className= "seccion-centro">
                <div className= "seccion-tabla">
                    {/* TITULO DE LA TABLA */}
                    <div className= "contenedor-titulo-tabla">
                        <h4 className= "titulo-tabla">
                            Estado de cuenta de colegiaturas
                        </h4>
                    </div>
                    {/* TABLA */}
                    <div className= "contenedor-tabla hover-elemento">
                        <table className= "tabla">
                        <thead>
                            <tr>
                                <th><label>Mes</label></th>
                                <th><label>Referencia</label></th>
                                <th><label>Fecha de pago</label></th>
                                <th className="center"><label>Importe</label></th>
                                <th className="center"><label>Recargos</label></th>
                                <th className="center"><label>Total</label></th>
                            </tr>
                        </thead>
                        <tbody>
                        { payments && payments.map( (payment, i) => {
                            return (
                                <tr key={i} >
                                    <td>{payment.MES_CARGO || ''}</td>
                                    <td>{payment.REFERENCIA || ''}</td>
                                    <td>{payment.FECHA_PAGO || ''}</td>
                                    <td align='center'>{payment.IMPORTE.toFixed(2) || ''}</td>
                                    <td align='center'>{payment.RECARGO.toFixed(2) || ''}</td>
                                    <td align='center'>{payment.PAGADO.toFixed(2) || ''}</td>
                                </tr>
                            );
                        }) }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>   
    );
};

export default Cuenta