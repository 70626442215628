import React from 'react';
import './Bienvenida.css';

const Bienvenida = () => {
    return (
      <div className= "contendor-bienvenida">
        <div className= "mensaje-bienvenida">
            <p>
            Para actualizar sus datos seleccionar la opción “Actualiza tus datos”, revisa y corrige si es 
            necesario alguna información, y si usted requiere factura es importante que llene los Datos 
            Fiscales.
            </p>
        </div>
      </div>
    );
  };
  
export default Bienvenida;