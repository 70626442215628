import React, {useState, useEffect} from 'react';
import HeaderComponent from '../../components/PagoInscripciones/Header/Header';
import InscripcionComponent from '../../components/PagoInscripciones/Inscripciones/Inscripcion';
import LoaderComponent from '../../components/Loader/Loader';
import swal from 'sweetalert';
import api from '../../services/api';

const InscripcionesPage = ({history}) => {

    const [payment, setPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentURL, setPaymentURL] = useState(false);
    const initialize = async() => {
        try { 
            const response = await api.getPaymentsInscription();
            const data = response.data.data;
            setPayment(data);
            window.Payment.setEnv("pro");
        }catch (e){
            if(!e.response || !e.response.data) {
                swal("Error", "Intente de nuevo más tarde.", "error");
                return;
            }
        }
    }

    useEffect( () => {
        initialize();
    }, []);

    const handleSubmit = (postData) => {
        if(isLoading) return;

        setIsLoading(true);
        // console.log("postData.referencia", postData.referencia);
        const referencia = postData.referencia;
        const params = {
            MerchantID: '8444971',
            User: 'americanenglishweb',
            Password: '8a4#j\'E3Y?',
            Mode: 'PRD',
            ControlNumber: referencia,
            TerminalID: '84449711',
            Amount: postData.importe,
            MerchantName: 'AMERICAN ENGLISH ACADE',
            MerchantCity: 'MAZATLAN',
            Language: 'ES',
        }
        
        const xObjEnc = window.Payment.encrypt({
            params
        });

        setIsLoading(false);

        window.Payment.startPayment({
            params: {
                crypt: xObjEnc
            },
            onClosed: function(response) {
                console.log("onClosed", response);
                setIsLoading(false);
            },
            onError: function(response) {// TO DO Code Here when an error occurred
                console.log("onError", response);
                setIsLoading(false);
            },
            onSuccess: function(response) {
                api.postPaymentResponse({data: response, tipo_cargo: 'INS'}).then(  response => {
                    setIsLoading(false);
                    initialize();
                }).catch( e => {
                    console.log("Error", e);
                });
                console.log("onSuccess", response);
            },
            onCancel: function(response) {
                // TO DO Code Here when user cancel operation
                console.log("onCancel", response);
                setIsLoading(false);
            }
        });
    }

    return (
        <div className= "contenedor-derecho fade-in">
            <LoaderComponent visible={isLoading} text='Generando Pago' />
            <div>
                <HeaderComponent />
            </div>
            <div>
            {
                paymentURL ? 
                    <iframe 
                        src={paymentURL}
                        width="80%" 
                        height="550px" 
                        frameBorder="0" 
                        scrolling="no"
                        seamless="seamless"
                        style={{display: 'block', margin:'0 auto'}}
                    ></iframe>
                    :
                    <InscripcionComponent payment={payment} onPaymentSubmit={handleSubmit} />
            }
            </div>
        </div>
    );
};

export default InscripcionesPage;
