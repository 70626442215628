import React from 'react'
import { NavLink } from 'react-router-dom'
import './Menu.css';

const Menu = () => {
    return (
        <div className= "contenedor-menu">
            <NavLink activeClassName= "activo" to= "/alumnos-react/panel/inicio">
                <div className= "contenedor-opc">
                    <div className= "opc">
                        Inicio
                    </div>
                </div>    
            </NavLink>
            <NavLink activeClassName= "activo" to= "/alumnos-react/panel/pago-inscripcion">
                <div className= "contenedor-opc">
                    <div className= "opc">
                        Pago de inscripcion
                    </div>
                </div>    
            </NavLink>
            {
            <NavLink activeClassName= "activo" to= "/alumnos-react/panel/pago-colegiaturas">
                <div className= "contenedor-opc">
                    <div className= "opc">
                        Pago de colegiaturas
                    </div>
                </div>    
            </NavLink>
            }
            <NavLink activeClassName= "activo" to= "/alumnos-react/panel/datos">
                <div className= "contenedor-opc">
                    <div className= "opc">
                        Actualiza tus datos
                    </div>
                </div>    
            </NavLink>
        </div>
    );
};

export default Menu;
