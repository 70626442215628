import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import LoginPage from './containers/Login/LoginPage';
import PanelPage from './containers/Panel/PanelPage';
import InicioPage from './containers/Inicio/Inicio';
import ColegiaturasPage from './containers/Colegiaturas/Colegiaturas';
import InscripcionesPage from './containers/InscripcionesPage/InscripcionesPage';
import DatosPage from './containers/Datos/Datos';
import PaymentsPage from './containers/PaymentsPage/PaymentsPage';

import './index.css';

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
      <Route exact path='/' component= {LoginPage} />
        <Route exact path='/alumnos-react/' component= {LoginPage} />
        <Route path='/alumnos-react/panel' component= {PanelPage} />
        <Switch>
          <Route path='/alumnos-react/panel/inicio' component= {InicioPage} />
          <Route path='/alumnos-react/panel/pago-colegiaturas' component= {ColegiaturasPage} />
          <Route path='/alumnos-react/panel/pago-inscripcion' component= {InscripcionesPage} />
          <Route path='/alumnos-react/panel/datos' component= {DatosPage} />
          <Route path='/alumnos-react/panel/payments' component= {PaymentsPage} />
          <Redirect from='/alumnos-react/panel' to='/alumnos-react/panel/inicio' />
        </Switch>
      </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('md-react-app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
