import React from 'react'
import './Mensualidad.css';
import swal from 'sweetalert';

const Mensualidad = ({payment, onPaymentSubmit}) => {

    const handleOnClick = (e) => {
        if(!payment) return;
        if(!payment.referencia || payment.referencia.length < 1) {
            swal("Error", "Referencia no valida", "error");
            return;
        }
        const data = { referencia: payment.referencia, importe: payment.total.toFixed(2) };
        onPaymentSubmit(data);
        e.preventDefault();
    }

    return (
        <div className= "seccion-mensualidad hover-seccion">
            <div className= "seccion-centro">
                <div className= "seccion-tabla">
                    {/* TITULO DE LA TABLA */}
                    <div className= "contenedor-titulo-tabla">
                        <h4 className= "titulo-tabla">
                            Mensualidad de colegiatura a pagar
                        </h4>
                    </div>
                {/* TABLA */}
                <div className= "contenedor-tabla hover-elemento">
                    <table className= "tabla">
                        <thead>
                        <tr>
                            <th><label>Mes a pagar</label></th>
                            <th><label>Referencia</label></th>
                            <th className='center'><label>Importe</label></th>
                            <th className='center'><label>Recargos</label></th>
                            <th className='center'><label>Total</label></th>
                        </tr>
                        </thead>
                        <tbody>
                        { payment ? 
                            <tr>
                                <td>{payment.MES_CARGO || ''}</td>
                                <td>{payment.referencia || ''}</td>
                                <td align='center'>{payment.IMPORTE.toFixed(2) || ''}</td>
                                <td align='center'>{payment.RECARGO.toFixed(2) || ''}</td>
                                <td align='center'>{payment.total.toFixed(2) || ''}</td>
                            </tr> :
                            <tr>
                                <td colSpan="5">
                                    No se encontraron colegiaturas pendientes.
                                </td>
                            </tr>
                        }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
                <div className= "subseccion hover-subseccion">
                {/* PAGO */}
                <div className="seccion-pago">
                    <div className= "tipo-pago">
                        <button className= "boton-pago" onClick={handleOnClick}>
                            Pagar
                        </button>
                    </div>
                </div>
            </div>
        </div>    
    );
};

export default Mensualidad;
