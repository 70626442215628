import React from 'react'
import './Header.css';

const Header = () => {
    return (
        <div className= "header-colegiaturas">
            <div className= "titulo-colegiaturas">
                <h2>
                    Pago de Colegiaturas
                </h2>
            </div>
        </div>      
    );
};

export default Header;