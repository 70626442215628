import React from 'react';
import './Informacion.css';

const Informacion = () => {
    return (
        <div>
            {/* TITULO */}
            <div className= "contenedor-titulo">
                <p className= "titulo-login">
                    Acceso a tu red escolar
                </p>
            </div>
            {/* PARRAFOS */}
            <div className= "contenedor-parrafo" style={{paddingBottom: '20px'}}>
                <p className= "parrafo-01">
                    Para Accesar a la Red Escolar deberá ser un Usuario Registrado. 
                    Por favor introduzca los dígitos de su Matrícula y la Contraseña que le fue proporcionada.
                </p>
                {/* <p className= "parrafo-02">
                    Por favor antes de Realizar su Pago, verifique sus datos Fiscales ya que la factura se genera automaticamente.
                </p> */}
            </div>
        </div>  
    );
};

export default Informacion;