import React, {useState, useEffect} from 'react';
import './Actualiza.css';
import api from '../../../services/api';
import swal from 'sweetalert';

const Actualiza = ({user}) => {

    const [usuario, setUsuario] = useState ({
        ciclo: '',
        matricula: '',
        nombreAlunmo: '',
        calleAlumno: '',
        coloniaAlumno: '',
        cpAlumno: '',
        poblacion: '',
        telCasa: '',
        correo: '',
        padre: '',
        profesionP: '',
        trabajoP: '',
        telTrabajoP: '',
        celularP: '',
        madre: '',
        profesionM: '',
        trabajoM: '',
        telTrabajoM: '',
        celularM: '',
        razonSocial: '',
        calleF: '',
        extF: '',
        intF: '',
        coloniaF: '',
        localidadF: '',
        municipioF: '',
        idEstadoF: '',
        cpF: '',
        paisF: '',
        rfcF: '',
        bancoF: '',
        cuentaF: ''
    });

    useEffect( () => {
        const initialize = async() => {
            try { 
                const response = await api.getData();
                const data = response.data.data;

                setUsuario({
                    ciclo: data.Ciclo,
                    matricula: data.Matricula,
                    nombreAlunmo: data.Alumno,
                    calleAlumno: data.Domicilio,
                    coloniaAlumno: data.Colonia,
                    cpAlumno: data.CP,
                    poblacion: data.Localidad,
                    telCasa: data.Telefono_Casa,
                    correo: data.Cuenta_Correo_Tutor,
                    padre: data.Nombre_Padre,
                    profesionP: data.Profesion_Padre,
                    trabajoP: data.Trabajo_Padre,
                    telTrabajoP: data.Telefono_Padre,
                    celularP: data.Celular_Padre,
                    madre: data.Nombre_Madre,
                    profesionM: data.Profesion_Madre,
                    trabajoM: data.Trabajo_Madre,
                    telTrabajoM: data.Telefono_Madre,
                    celularM: data.Celular_Madre,
                    razonSocial: data.Razon_Social,
                    calleF: data.Domicilio_Fiscal,
                    extF: data.No_Exterior,
                    intF: data.No_Interior,
                    coloniaF: data.Colonia_Fiscal,
                    localidadF: data.Ciudad_Fiscal,
                    municipioF: data.Municipio_Fiscal,
                    idEstadoF: data.Id_Estado_Fiscal,
                    cpF: data.CP_Fiscal,
                    paisF: data.Pais_Fiscal,
                    rfcF: data.RFC,
                    bancoF: data.Banco,
                    cuentaF: data.Numero_Cuenta
                });

            }catch (e){
                console.log("error", e);
                if(!e.response || !e.response.data) {
                    swal("Error", "Intente de nuevo más tarde.", "error");
                    return;
                }
            }
        }

        initialize();

    }, []);

    const handleInputData = (e) => {
        setUsuario({
            ...usuario,
            [e.target.name]: e.target.value
        });
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            user(usuario)
        } catch (error) {
            console.log(error)
        }
    }

    const handleNumeros = (e) => {
        const re = /^\d{1,}(\.\d{0,4})?$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setUsuario({
                ...usuario,
                [e.target.name]: e.target.value
            });
        }
    }

    // HOVER GUARDAR
    const [hover, setHover]= useState(false);

    return (
      <div>
            <form onSubmit= {handleSubmit}>
                <div className= "subtitulo-datos">
                    <h3>Datos del Alumno</h3>
                </div>
                {/* ALUMNO */}
                <div className= {!hover ? 'seccion-datos hover-seccion':'seccion-datos hover-general'} >
                    <div className= "seccion-centro">
                        <div className= "contenedor-input-datos">
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Matricula:
                                </label>
                                <div style= {{padding: '3px 10px 3px 10px'}}>
                                    {usuario.matricula}
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Nombre del Alumno:
                                </label>
                                <div style= {{padding: '3px 10px 3px 10px'}}>
                                    {usuario.nombreAlunmo}
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Calle:
                                </label>
                                <div>
                                    <input maxLength= "50" type="text"
                                    className= "input-text-datos contenedor-normal"
                                    onChange= {handleInputData}
                                    name= "calleAlumno" 
                                    style= {{width: '300px'}}
                                    value= {usuario.calleAlumno} />
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Colonia:
                                    </label>
                                    <div>
                                        <input maxLength= "30" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "coloniaAlumno"
                                        onChange= {handleInputData}
                                        value= {usuario.coloniaAlumno} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        C.P.:
                                    </label>
                                    <div>
                                        <input maxLength="5" type="text"
                                        name= "cpAlumno"
                                        onChange= {handleNumeros}
                                        className= "input-text-datos contenedor-corto"
                                        value= {usuario.cpAlumno} />
                                    </div>
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Población:
                                </label>
                                <div>
                                    <input maxLength= "30" type="text"
                                    className= "input-text-datos contenedor-normal"
                                    name= "poblacion"
                                    onChange= {handleInputData}
                                    value= {usuario.poblacion} />
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Telefóno de Casa:
                                </label>
                                <div>
                                    <input maxLength= "30" type="text"
                                    name= "telCasa"
                                    onChange= {handleInputData}
                                    className= "input-text-datos contenedor-normal"
                                    value= {usuario.telCasa} />
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Correo Electrónico:
                                </label>
                                <div>
                                    <input maxLength= "100"
                                    type= "email" 
                                    name= "correo" 
                                    className= "input-text-datos contenedor-normal" 
                                    onChange= {handleInputData}
                                    value= {usuario.correo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className= {!hover ? 'seccion-datos hover-seccion':'seccion-datos hover-general'} >
                    <div className= "subseccion-datos">
                        {/* PADRE */}
                        <div className= "contenedor-mitad-datos hover-subseccion">
                            <div className= "seccion-centro">
                                <div className= "contenedor-input-datos">
                                    <div>
                                        <label className= "nombre-dato">
                                            Nombre del Padre:
                                        </label>
                                        <div style= {{padding: '3px 0px 3px 0px'}}>
                                            <input maxLength= "50" type="text"
                                            className= "input-text-datos contenedor-largo"
                                            name= "padre"
                                            onChange= {handleInputData}
                                            value= {usuario.padre} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Profesión:
                                        </label>
                                        <div>
                                            <input maxLength= "150" type="text"
                                            className= "input-text-datos contenedor-normal"
                                            name= "profesionP"
                                            onChange= {handleInputData}
                                            value= {usuario.profesionP} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Institución / Trabajo:
                                        </label>
                                        <div>
                                            <input maxLength= "150" type="text"
                                            className= "input-text-datos contenedor-normal"
                                            name= "trabajoP"
                                            onChange= {handleInputData}
                                            value= {usuario.trabajoP} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Tel. Trabajo:
                                        </label>
                                        <div>
                                            <input maxLength="30" type="text"
                                            name= "telTrabajoP"
                                            onChange= {handleInputData}
                                            className= "input-text-datos contenedor-normal"
                                            value= {usuario.telTrabajoP} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Celular:
                                        </label>
                                        <div>
                                            <input maxLength="30" type="text"
                                            name= "celularP"
                                            onChange= {handleInputData}
                                            className= "input-text-datos contenedor-normal"
                                            value= {usuario.celularP} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* MADRE */}
                        <div className= "contenedor-mitad-datos hover-subseccion">
                            <div className= "seccion-centro">
                                <div className= "contenedor-input-datos">
                                    <div>
                                        <label className= "nombre-dato">
                                            Nombre de la Madre:
                                        </label>
                                        <div style= {{padding: '3px 0px 3px 0px'}}>
                                            <input maxLength= "50" type="text"
                                            className= "input-text-datos contenedor-largo"
                                            name= "madre"
                                            onChange= {handleInputData}
                                            value= {usuario.madre} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Profesión:
                                        </label>
                                        <div>
                                            <input maxLength= "150" type="text" 
                                            className= "input-text-datos contenedor-normal"
                                            name= "profesionM"
                                            onChange= {handleInputData}
                                            value= {usuario.profesionM} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Institución / Trabajo:
                                        </label>
                                        <div>
                                            <input maxLength= "150" type="text"
                                            className= "input-text-datos contenedor-normal"
                                            name= "trabajoM"
                                            onChange= {handleInputData}
                                            value= {usuario.trabajoM} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Tel. Trabajo:
                                        </label>
                                        <div>
                                            <input maxLength="30" type="text"
                                            name= "telTrabajoM"
                                            onChange= {handleInputData}
                                            className= "input-text-datos contenedor-normal"
                                            value= {usuario.telTrabajoM} />
                                        </div>
                                    </div>
                                    <div className= "seccion-input">
                                        <label className= "nombre-dato">
                                            Celular:
                                        </label>
                                        <div>
                                            <input maxLength="30" type="text"
                                            name= "celularM"
                                            onChange= {handleInputData}
                                            className= "input-text-datos contenedor-normal"
                                            value= {usuario.celularM} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className= "subtitulo-datos">
                    <h3>Datos Fiscales</h3>
                </div>
                <div className= {!hover ? 'seccion-datos hover-seccion':'seccion-datos hover-general'} >
                    <div className= "seccion-centro">
                        <div className= "contenedor-input-datos">
                            <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Razón Social:
                                </label>
                                <div>
                                    <input maxLength= "100" type="text"
                                    className= "input-text-datos contenedor-largo"
                                    name= "razonSocial"
                                    onChange= {handleInputData}
                                    value= {usuario.razonSocial} />
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Calle:
                                    </label>
                                    <div>
                                        <input maxLength= "150" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "calleF"
                                        onChange= {handleInputData}
                                        value= {usuario.calleF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        No. Ext.:
                                    </label>
                                    <div>
                                        <input maxLength= "10" type="text"
                                        className= "input-text-datos contenedor-corto"
                                        name= "extF"
                                        onChange= {handleInputData}
                                        value= {usuario.extF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        No. Int.:
                                    </label>
                                    <div>
                                        <input maxLength= "10" type="text"
                                        className= "input-text-datos contenedor-corto"
                                        name= "intF"
                                        onChange= {handleInputData}
                                        value= {usuario.intF} />
                                    </div>
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Colonia:
                                    </label>
                                    <div>
                                        <input maxLength= "50" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "coloniaF"
                                        onChange= {handleInputData}
                                        value= {usuario.coloniaF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Localidad:
                                    </label>
                                    <div>
                                        <input maxLength= "50" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "localidadF"
                                        onChange= {handleInputData}
                                        value= {usuario.localidadF} />
                                    </div>
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Municipio:
                                    </label>
                                    <div>
                                        <input maxLength= "50" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "municipioF"
                                        onChange= {handleInputData}
                                        value= {usuario.municipioF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Estado:
                                    </label>
                                    <div>
                                        <select className= "input-text-datos" value= {usuario.idEstadoF} name= "idEstadoF" onChange= {handleInputData}>
                                            <option value= "0"></option>
                                            <option value= "1">AGUASCALIENTES</option>
                                            <option value= "2">BAJA CALIFORNIA</option>
                                            <option value= "3">BAJA CALIFORNIA SUR</option>
                                            <option value= "4">CAMPECHE</option>
                                            <option value= "5">COAHUILA</option>
                                            <option value= "6">COLIMA</option>
                                            <option value= "7">CHIAPAS</option>
                                            <option value= "8">CHIHUAHUA</option>
                                            <option value= "9">CIUDAD DE MEXICO</option>
                                            <option value= "10">DURANGO</option>
                                            <option value= "11">ESTADO DE MEXICO</option>
                                            <option value= "12">GUANAJUATO</option>
                                            <option value= "13">GUERRERO</option>
                                            <option value= "14">HIDALGO</option>
                                            <option value= "15">JALISCO</option>
                                            <option value= "16">MICHOACAN</option>
                                            <option value= "17">MORELOS</option>
                                            <option value= "18">NAYARIT</option>
                                            <option value= "19">NUEVO LEON</option>
                                            <option value= "20">OAXACA</option>
                                            <option value= "21">PUEBLA</option>
                                            <option value= "22">QUERETARO</option>
                                            <option value= "23">QUINTANA ROO</option>
                                            <option value= "24">SAN LUIS POTOSI</option>
                                            <option value= "25">SINALOA</option>
                                            <option value= "26">SONORA</option>
                                            <option value= "27">TABASCO</option>
                                            <option value= "28">TAMAULIPAS</option>
                                            <option value= "29">TLAXCALA</option>
                                            <option value= "30">VERACRUZ</option>
                                            <option value= "31">YUCATAN</option>
                                            <option value= "32">ZACATECAS</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className= "seccion-input">
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        C.P.:
                                    </label>
                                    <div>
                                        <input maxLength="5" type="text"
                                        name= "cpF"
                                        onChange= {handleNumeros}
                                        className= "input-text-datos contenedor-corto"
                                        value= {usuario.cpF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        País:
                                    </label>
                                    <div>
                                        <input maxLength= "20" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "paisF"
                                        onChange= {handleInputData}
                                        value= {usuario.paisF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        RFC:
                                    </label>
                                    <div>
                                        <input maxLength= "15" type="text"
                                        onChange= {handleInputData}
                                        name= "rfcF"
                                        className= "input-text-datos contenedor-normal" maxLength= "13"
                                        value= {usuario.rfcF} />
                                    </div>
                                </div>
                                
                            </div>
                            <div className= "seccion-input">
                                <div className= "seccion-input">
                                    <label className= "nombre-dato">
                                        Banco:
                                    </label>
                                    <div>
                                        <input maxLength= "20" type="text"
                                        className= "input-text-datos contenedor-normal"
                                        name= "bancoF"
                                        onChange= {handleInputData}
                                        value= {usuario.bancoF} />
                                    </div>
                                </div>
                                <div className= "seccion-input">
                                <label className= "nombre-dato">
                                    Número de Cuenta:
                                </label>
                                <div>
                                    <input maxLength="20" type="text"
                                    name= "cuentaF"
                                    onChange= {handleNumeros}
                                    className= "input-text-datos contenedor-normal"
                                    value= {usuario.cuentaF} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className= "hover-subseccion"
            onMouseEnter= {() => setHover(true)}
            onMouseLeave= {() => setHover(false)} >
                <div className= "seccion-guardar">
                    <button 
                    type= "submit"
                    className= "boton-guardar">
                        Guardar información
                    </button>
                </div>
            </div>
        </form>
      </div>
    );
  };
  
export default Actualiza;