import React, {useState, useEffect} from 'react';
import HeaderComponent from '../../components/PagoInscripciones/Header/Header';
import InscripcionComponent from '../../components/PagoInscripciones/Inscripciones/Inscripcion';
import LoaderComponent from '../../components/Loader/Loader';
import swal from 'sweetalert';
import api from '../../services/api';

const PaymentsPage = ({history}) => {

    const [payment, setPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentURL, setPaymentURL] = useState(false);

    useEffect( () => {
        const initialize = async() => {
            setIsLoading(false);
            console.log("history", history);
        }

        initialize();

    }, []);

    return (
        <div className= "contenedor-derecho fade-in">
            <LoaderComponent visible={isLoading} text='Generando Pago' />
            <div>
                <HeaderComponent />
            </div>
            <div>
                thanks
            </div>
        </div>
    );
};

export default PaymentsPage;
