import React from 'react';
import './Inscripcion.css';
import swal from 'sweetalert';

const Inscripcion = ({payment, onPaymentSubmit}) => {

    const handleOnClick = (e) => {
        if(!payment) return;
        if(!payment.REFERENCIA1 || payment.REFERENCIA1.length < 1) {
            swal("Error", "Referencia no valida", "error");
            return;
        }
        const data = { referencia: payment.REFERENCIA1, importe: payment.IMPORTE.toFixed(2) };
        onPaymentSubmit(data);
        e.preventDefault();
    }

    return (
        <div className= "seccion-inscripcion hover-seccion">
            <div className= "seccion-centro">
                <div className= "seccion-tabla">
                    {/* TITULO DE LA TABLA */}
                    <div className= "contenedor-titulo-tabla">
                        <h4 className= "titulo-tabla">
                            Inscripción a pagar
                        </h4>
                    </div>
                    {/* TABLA */}
                    <div className= "contenedor-tabla hover-elemento">
                        <table className= "tabla">
                            <thead>
                            <tr>
                                <th><label>Mes a pagar</label></th>
                                <th><label>Referencia</label></th>
                                <th><label>Importe</label></th>
                            </tr>
                            </thead>
                            <tbody>
                            { payment ? 
                                <tr>
                                    <td>{payment.concepto || ''}</td>
                                    <td>{payment.REFERENCIA1 || ''}</td>
                                    <td>{payment.IMPORTE.toFixed(2) || ''}</td>
                                </tr> :
                                <tr>
                                    <td colSpan="5">
                                        No se encontraron pagos pendientes.
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className= "subseccion hover-subseccion">
                {/* PAGO */}
                <div className="seccion-pago">
                    <div className= "tipo-pago">
                        <button className= "boton-pago" onClick={handleOnClick}>
                            Pagar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inscripcion;
