import * as HttpService from './http';

import { http } from '../utils/constants';

const hasResponse = (res) => !res.success || res.responseCode !== 200 || !res.responseData;
const getToken = () => {
  const token = localStorage.getItem('appToken');
  if(!token) {
    return false;
  }

  return token;
}

const postLogin = async(matricula, password) => {
  const url = `${http.API_END_POINT.BASE}/auth`;
  const method = http.METHOD_TYPE.POST;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const data = {
    matricula,
    password,
  }

  return HttpService.request(method, header, data, url)
    .then((response) => {
      return response;
    });
};

const getUserInfo = async(a) => {
  const url = `${http.API_END_POINT.BASE}/auth/me`;
  const method = http.METHOD_TYPE.GET;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, null, url)
    .then((response) => {
      return response;
    });
};

const getPaymentsInscription = async(a) => {
  const url = `${http.API_END_POINT.BASE}/payments/inscription`;
  const method = http.METHOD_TYPE.GET;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, null, url)
    .then((response) => {
      return response;
    });
};


const getPaymentsTuition = async(a) => {
  const url = `${http.API_END_POINT.BASE}/payments/tuition`;
  const method = http.METHOD_TYPE.GET;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, null, url)
    .then((response) => {
      return response;
    });
};

const postPaymentResponse = async(data) => {
  const url = `${http.API_END_POINT.BASE}/payments/response`;
  const method = http.METHOD_TYPE.POST;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, data, url)
    .then((response) => {
      return response;
    });
};

const getGrades = async(a) => {
  const url = `${http.API_END_POINT.BASE}/grades/calificaciones`;
  const method = http.METHOD_TYPE.GET;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, null, url)
    .then((response) => {
      return response;
    });
};

const getData = async(a) => {
  const url = `${http.API_END_POINT.BASE}/updates/actualizaciones`;
  const method = http.METHOD_TYPE.GET;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, null, url)
    .then((response) => {
      return response;
    });
};

const postNewData = async(user) => {
  const url = `${http.API_END_POINT.BASE}/updates/actualizar`;
  const method = http.METHOD_TYPE.POST;

  const header = {
    'Content-Type': http.CONTENT_TYPE.APPLICATION_JSON,
  };

  // console.log("api",user);

  const token = getToken();
  if(token) {
    header['Authorization']=token;
  }

  return HttpService.request(method, header, user, url)
    .then((response) => {
      return response;
    });
};


export default {
  postLogin,
  getUserInfo,
  getPaymentsInscription,
  getPaymentsTuition,
  getGrades,
  getData,
  postNewData,
  postPaymentResponse,
};