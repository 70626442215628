import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import api from '../../services/api';
// import './Datos.css';
import HeaderComponent from '../../components/Datos/Header/Header';
import DatosComponent from '../../components/Datos/Actualiza/Actualiza';
import LoaderComponent from '../../components/Loader/Loader';

const Datos = () => {

  const [isLoading, setIsLoading] = useState(false);

  async function user(user) {
    console.log("container data",user);
    if(isLoading) return;
    setIsLoading(true);
    try { 
      const response = await api.postNewData(user);
      console.log("response", response);
      setIsLoading(false);
    }catch (e){
      setIsLoading(false);
      if(!e.response || !e.response.data) {
        swal("Error", "Intente de nuevo más tarde.", "error");
        return;
      }
      console.log("ERROR" , e.response);
    }
  }

  return (
    <div className= "contenedor-derecho fade-in">
      <LoaderComponent visible={isLoading} text='Guardando cambios' />
        <div>
          <HeaderComponent />
        </div>
        <div>
          <DatosComponent user= {user} />
        </div>
    </div>
  );
};
  
export default Datos;